import { data } from "../component/Dashboard/DashboardGraph";

const initialState = {
  modules: [],
  videoDetails: {},
  qnaList: [],
  allAssignments: [],
  allResources: {},
  currentBatchData: {},
  courseAnalytics: {},
  assignmentDetails: {},
  meetlink: "",
  notification: {},
  notificationInfo: {
    isNotification: false,
    isAnnouncement: false,
    isAllUpdates: false,
  },
  batch_start: "",
  isUserAllowed: false,
  flashMsg: false,
};

const batchReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_BATCH_DATA":
      return {
        ...state,
        modules: payload.modules,
        meetlink: payload.meetlink ? payload.meetlink : "",
        batch_start: payload.batch_start,
      };
    case "SET_VIDEO_DETAILS":
      return {
        ...state,
        videoDetails: payload,
      };
    case "SET_QNA_DATA":
      return {
        ...state,
        qnaList: payload,
      };
    case "SET_ALL_ASSIGNMENTS":
      return {
        ...state,
        allAssignments: payload,
      };
    case "SET_ASSIGNMENT_RESOURCES":
      return {
        ...state,
        allResources: payload,
      };
    case "SET_ALL_CURRENT_BATCHES":
      return {
        ...state,
        currentBatchData: payload,
      };
    case "SET_MONTH_COURSE_ANALYTICS":
      return {
        ...state,
        courseAnalytics: payload,
      };
    case "SET_ASSIGNMENT_PROGRESS":
      return {
        ...state,
        assignmentDetails: payload,
      };
    case "SET_MY_NOTIFICATION":
      return {
        ...state,
        notification: payload,
      };
    case "SET_NOTIFICATION_INFO":
      return {
        ...state,
        notificationInfo: payload,
      };
    case "SET_USER_ALLOWED":
      return {
        ...state,
        isUserAllowed: payload,
      };
    case "SET_FLASH_MSG":
      return {
        ...state,
        flashMsg: payload,
      };
    default:
      return state;
  }
};

export default batchReducer;
