import { Children, useEffect } from "react";
import { cloneElement } from "react";
import toast from "react-hot-toast";
import { Navigate, Route } from "react-router-dom";
import ReactVisibilitySensor from "react-visibility-sensor";
import { motion } from "framer-motion";
import axios from "../axios";
import platform from "platform";

const importAll = (r) => {
  let images = {};
  r.keys().map((item) => {
    return (images[item.replace("./", "")] = r(item));
  });
  return images;
};

const images = importAll(require.context("../asset/images", false));

const checkLogin = () => {
  var status = false;
  if (localStorage.getItem("oeson_token") != null) {
    status = true;
  }
  return status;
};

const notifySuccess = (notification) => toast.success(notification);

const notifyDanger = (notification) => toast.error(notification);

const GetRoutes = (allRoutes) =>
  allRoutes.map((route) => {
    if (route.path) {
      return (
        <Route
          exact
          path={route.path}
          element={
            route.private === true ? (
              checkLogin() === true ? (
                route.page
              ) : (
                <Navigate to="/" />
              )
            ) : (
              route.page
            )
          }
          key={route.key}
        />
      );
    }

    return null;
  });

const confirmToast = (text, func) => {
  toast(
    (t) => (
      <div className="custom-toast">
        <h5>{text}</h5>
        <div className="d-flex justify-content-end" style={{ gap: "10px " }}>
          <button
            className="btn btn-success"
            onClick={() => {
              func();
              toast.dismiss(t.id);
            }}
          >
            OK
          </button>
          {/* <button className="btn btn-danger" onClick={() => toast.dismiss(t.id)}>
            No
          </button> */}
        </div>
      </div>
    ),
    { duration: 100000 }
  );
};

const toastConfirm = (text, func) => {
  toast(
    (t) => (
      <div className="custom-toast">
        <h5>{text}</h5>
        <div className="d-flex justify-content-end" style={{ gap: "10px " }}>
          <button
            className="btn btn-success"
            onClick={() => {
              func();
              toast.dismiss(t.id);
            }}
          >
            Yes
          </button>
          <button
            className="btn btn-danger"
            onClick={() => toast.dismiss(t.id)}
          >
            No
          </button>
        </div>
      </div>
    )
    // { duration: 100000 }
  );
};

function updateMetaTagContent(ID, newContent) {
  var metaTag = document.getElementById(ID);
  if (metaTag) {
    metaTag.setAttribute("content", newContent);
  }
}

function updateTitle(ID, newContent) {
  var titleTag = document.getElementById(ID);
  if (titleTag) {
    titleTag.textContent = newContent;
  }
}

const addLazyLoadingAttribute = (element) => {
  if (!element) {
    return element;
  }

  // If the element has a type of 'img', add the loading="lazy" attribute
  if (element.type === "img") {
    const updatedElement = cloneElement(element, { loading: "lazy" });
    return updatedElement;
  }

  // If it's a regular element with children, recursively process its children
  if (element.props && element.props.children) {
    const updatedChildren = Children.map(element.props.children, (child) =>
      addLazyLoadingAttribute(child)
    );
    return cloneElement(element, {}, updatedChildren);
  }

  return element;
};

// Function to wrap your JSX with lazy loading attribute
const LazyLoadingWrapper = ({ children }) => {
  const lazyLoadedChildren = Children.map(children, (child) =>
    addLazyLoadingAttribute(child)
  );

  return <>{lazyLoadedChildren}</>;
};

const FadeInWrapper = ({ children, onChange }) => {
  return (
    <div
      data-aos="fade-in"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
    >
      {children}
    </div>
  );
};

const FadeLeftWrapper = ({ children, onChange }) => {
  return (
    <ReactVisibilitySensor onChange={onChange} partialVisibility={true}>
      {({ isVisible }) => (
        <motion.div
          initial={{ opacity: 0, x: -200 }} // Initial state (invisible)
          animate={isVisible ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1 }} // Animation duration in seconds
        >
          {children}
        </motion.div>
      )}
    </ReactVisibilitySensor>
  );
};

const FadeRightWrapper = ({ children, onChange }) => {
  return (
    <ReactVisibilitySensor onChange={onChange} partialVisibility={true}>
      {({ isVisible }) => (
        <motion.div
          initial={{ opacity: 0, x: 200 }} // Initial state (invisible)
          animate={isVisible ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1 }} // Animation duration in seconds
        >
          {children}
        </motion.div>
      )}
    </ReactVisibilitySensor>
  );
};

const FadeOutWrapper = ({ children, onChange }) => {
  return (
    <ReactVisibilitySensor onChange={onChange} partialVisibility={true}>
      {({ isVisible }) => (
        <motion.div
          initial={{ opacity: 0, y: -100 }} // Initial state (invisible)
          animate={isVisible ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.5 }} // Animation duration in seconds
        >
          {children}
        </motion.div>
      )}
    </ReactVisibilitySensor>
  );
};

const RazorPayBtn = (
  isAccept,
  setIsLoading,
  data,
  amountInUsd,
  afterPayment,
  razorpayConfirm
) => {
  setIsLoading(true);
  if (isAccept) {
    data.amount = amountInUsd.amount;
    data.currency = amountInUsd.currency;
  }

  axios
    .post("/payment/create", data)
    .then((res) => {
      setIsLoading(false);
      const { data } = res;

      const notesObj = {
        contact: data.contact,
        name: data.name,
        email: data.email,
        address: data.address,
        country: data.country,
        gender: data.gender,
      };
      const notes = JSON.stringify(notesObj);

      const options = {
        key: data.key_id,
        amount: data.amount,
        currency: data.currency,
        order_id: data.order_id,
        handler: function () {
          afterPayment();
        },
        prefill: {
          contact: data.contact,
          name: data.name,
          email: data.email,
          address: data.address,
          country: data.country,
          gender: data.gender,
        },
        description: notes,
        notes: notes,
        theme: {
          color: "#024c87",
        },
      };
      const razorpayObject = new window.Razorpay(options);
      razorpayObject.on("payment.failed", function (res) {
        notifyDanger("Payment Failed");
      });
      razorpayObject.open();
    })
    .catch((err) => {
      confirmToast(
        "Your currency is not supported please pay in Dollars",
        razorpayConfirm
      );
      setIsLoading(false);
    });
};

const createPaypalButton = (
  amount,
  amountInUsd,
  userInfo,
  isPaypalFail,
  paypalConfirm,
  afterPayment
) => {
  let IncreaseAmount = Math.floor(amount + amount * (2.5 / 100));
  if (isPaypalFail) {
    IncreaseAmount = Math.floor(
      amountInUsd.amount + amountInUsd.amount * (2.5 / 100)
    );
  }

  window.paypal
    .Buttons({
      createOrder: function (data, actions) {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                currency_code: isPaypalFail
                  ? amountInUsd.currency
                  : userInfo.currency,
                value: IncreaseAmount,
              },
            },
          ],
        });
      },
      onApprove: function (data, actions) {
        return actions.order.capture().then(function (details) {
          afterPayment();
        });
      },
      onError: function (err) {
        confirmToast(
          "Your currency is not supported click again on paypal button to pay in dollars",
          paypalConfirm
        );
      },
    })
    .render("#paypal-button-container");
};

const loadScriptTag = (callback, currency) => {
  var scriptTag = document.createElement("script");
  scriptTag.src = `https://www.paypal.com/sdk/js?client-id=AepIC_tb-Z7AebO9PDgcTAZ_1vrodDRJmPe1HfcGncOMaZP3lJE2oOQhsCnrTIYxslemZLbAVlAVwTKV&currency=${currency}`;
  scriptTag.async = true;
  scriptTag.onload = callback;
  document.body.appendChild(scriptTag);
};

const handleScrollTo = (ID) => {
  const element = document.getElementById(ID);
  element.scrollIntoView({ behavior: "smooth" });
};

const handleScrollToError = (className) => {
  const element = document.querySelector(`.${className}`);
  if (element) {
    element?.previousElementSibling.scrollIntoView({ behavior: "smooth" });
  }
};

function useOutsideClick(ref, setIsOpen) {
  useEffect(() => {
    function handleClickOutside(event) {
      if ( ref && ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const getFileExtension = (filename) => {
  const parts = filename.split(".");
  return parts[parts.length - 1].toLowerCase();
};

export default function getPlatformInfo() {
  const deviceInfo = {
        os: platform.os.family,
        osVersion: platform.os.version,
        browser: platform.name,
        browserVersion: platform.version,
        device: platform.product ? platform.product.name : 'Unknown Device',
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        language: navigator.language,
        userAgent: navigator.userAgent
  };
  return deviceInfo;
}


export {
  handleScrollToError,
  GetRoutes,
  images,
  notifySuccess,
  notifyDanger,
  updateMetaTagContent,
  updateTitle,
  confirmToast,
  LazyLoadingWrapper,
  FadeInWrapper,
  FadeLeftWrapper,
  FadeRightWrapper,
  FadeOutWrapper,
  RazorPayBtn,
  createPaypalButton,
  loadScriptTag,
  handleScrollTo,
  toastConfirm,
  useOutsideClick,
  getFileExtension
};

// const arr  = [
//   [1 , 2 ,3 , 4 ,  5 , 6 ],
//   [7 , 8 , 9, 10 , 11 , 12],
//   [13 , 14 , 15 , 16 , 17, 18]
// ]

// // output 1 , 2 , 3, 4, 5, 6, 12 , 18 , 17 , 16 , 15 , 14 , 13 , 7 , 8 , 9 , 10 , 11
