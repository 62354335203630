import React, { lazy } from "react";

// import { LoginSignout } from "./pages/LoginSignOut";
// import EnrollNow from "./pages/EnrollNow";
// import ThankYou from "./pages/ThankYou";
// import TermsAndCondition from "./pages/TermsAndCondition";
// import RefundPolicy from "./pages/RefundPolicy";
// import PrivacyPolicy from "./pages/PrivacyPolicy";
// import About from "./pages/About";
// import ApplicationSubmit from "./pages/ApplicationSubmit";
// import Apply from "./pages/Apply";
// import Blogdescription from "./pages/Blogdescription";
// import Blogs from "./pages/Blogs";
// import ContactUs from "./pages/ContactUs";
// import Home from "./pages/Home";
// import JobDescription from "./pages/JobDescription";
// import MeetOurTeam from "./pages/MeetOurTeam";
// import Mentors from "./pages/Mentors";
// import OurJobs from "./pages/OurJobs";
// import OurPolicy from "./pages/OurPolicy";
// import PlacedCandidate from "./pages/PlacedCandidate";
// import StudyAndInternship from "./pages/StudyAndInternship";
// import TestimonialVideo from "./pages/TestimonialVideo";
// import WhatTestimonies from "./pages/WhatTestimonies";
// import Program from "./pages/Program";
// import BecomeInstructor from "./pages/BecomeInstructor";
// import HireUs from "./pages/HireUs";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const ApplicationSubmit = lazy(() => import("./pages/ApplicationSubmit"));
const Apply = lazy(() => import("./pages/Apply"));
const Blogdescription = lazy(() => import("./pages/Blogdescription"));
const Blogs = lazy(() => import("./pages/Blogs"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const JobDescription = lazy(() => import("./pages/JobDescription"));
const MeetOurTeam = lazy(() => import("./pages/MeetOurTeam"));
const Mentors = lazy(() => import("./pages/Mentors"));
const OurJobs = lazy(() => import("./pages/OurJobs"));
const OurPolicy = lazy(() => import("./pages/OurPolicy"));
const PlacedCandidate = lazy(() => import("./pages/PlacedCandidate"));
const StudyAndInternship = lazy(() => import("./pages/StudyAndInternship"));
const TestimonialVideo = lazy(() => import("./pages/TestimonialVideo"));
const WhatTestimonies = lazy(() => import("./pages/WhatTestimonies"));
const Program = lazy(() => import("./pages/Program"));
const BecomeInstructor = lazy(() => import("./pages/BecomeInstructor"));
const HireUs = lazy(() => import("./pages/HireUs"));
const TermsAndCondition = lazy(() => import("./pages/TermsAndCondition"));
const RefundPolicy = lazy(() => import("./pages/RefundPolicy"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const EnrollNow = lazy(() => import("./pages/EnrollNow"));
const ThankYou = lazy(() => import("./pages/ThankYou"));
const DashBoardRoot = lazy(() => import("./pages/DashBoardRoot"));
const Bootcamp = lazy(() => import("./pages/Bootcamp"));
const AuthRootPage = lazy(() => import("./pages/AuthRootPage"));
const EnrollPage = lazy(() => import("./pages/EnrollPage"));
const Masterclass = lazy(() => import("./pages/Masterclass"));
const Test = lazy(() => import("./pages/Test"));
// const BlogDashboardRoot = lazy(()=>import("./pages/BlogDashboardRoot"))
const BlogDashBoard = lazy(() => import("./pages/BlogDashBoard"));
const BlogDashboardRegister = lazy(() =>
  import("./pages/BlogDashboardRegister")
);
const BlogSignUp = lazy(() => import("./pages/BlogSignup"));
const BlogOtp = lazy(() => import("./pages/BlogOtp"));
const CertificateInfo = lazy(()=> import("./pages/CertificateInfo"))

const RoutesArr = [
  {
    name: "Home",
    path: "/",
    key: "Home",
    route: "/",
    page: <Home />,
  },
  {
    name: "About",
    path: "/about",
    key: "About",
    route: "/about",
    page: <About />,
  },
  {
    name: "MeetOurTeam",
    path: "/meet-our-team",
    key: "MeetOurTeam",
    route: "/meet-our-team",
    page: <MeetOurTeam />,
  },
  {
    name: "our-jobs",
    path: "/jobs",
    key: "our-jobs",
    route: "/jobs",
    page: <OurJobs />,
  },
  {
    name: "job-description",
    path: "/job-description/:id",
    key: "job-description",
    route: "/job-description",
    page: <JobDescription />,
  },
  {
    name: "application-submit",
    path: "/application-submit",
    key: "application-submit",
    route: "/application-submit",
    page: <ApplicationSubmit />,
  },
  {
    name: "contact-us",
    path: "/contact-us",
    key: "contact-us",
    route: "/contact-us",
    page: <ContactUs />,
  },
  {
    name: "our-policy",
    path: "/our-policy",
    key: "our-policy",
    route: "/our-policy",
    page: <OurPolicy />,
  },
  {
    name: "blogs",
    path: "/blogs",
    key: "blogs",
    route: "/blogs",
    page: <Blogs />,
  },
  {
    name: "blog-description",
    path: "/blog-description/:id",
    key: "blog-description",
    route: "/blog-description/:id",
    page: <Blogdescription />,
  },
  {
    name: "blog-preview",
    path: "/blog-preview/:id",
    key: "blog-preview",
    route: "/blog-preview/:id",
    page: <Blogdescription />,
  },
  {
    name: "testimonial-video",
    path: "/testimonial-video",
    key: "testimonial-video",
    route: "/testimonial-video",
    page: <TestimonialVideo />,
  },
  {
    name: "mentors",
    path: "/mentors",
    key: "mentors",
    route: "/mentors",
    page: <Mentors />,
  },
  {
    name: "placed-candidates",
    path: "/placed-candidates",
    key: "placed-candidates",
    route: "/placed-candidates",
    page: <WhatTestimonies />,
  },
  {
    name: "reviews",
    path: "/reviews",
    key: "reviews",
    route: "/reviews",
    page: <PlacedCandidate />,
  },
  {
    name: "study-and-internship",
    path: "/study-and-internship",
    key: "study-and-internship",
    route: "/study-and-internship",
    page: <StudyAndInternship />,
  },

  {
    name: "ogtip",
    path: "/ogtip",
    key: "ogtip",
    route: "/ogtip",
    page: <StudyAndInternship />,
  },
  {
    name: "apply",
    path: "/apply",
    key: "apply",
    route: "/apply",
    page: <Apply />,
  },
  {
    name: "virtual-internship-experiance",
    path: "/virtual-internship-experiance",
    key: "virtual-internship-experiance",
    route: "/virtual-internship-experiance",
    page: <TestimonialVideo />,
  },
  {
    name: "placed-testimonies",
    path: "/placed-testimonies",
    key: "placed-testimonies",
    route: "/placed-testimonies",
    page: <WhatTestimonies />,
  },
  {
    name: "ogtip",
    path: "/ogtip/:id",
    key: "ogtip",
    route: "/ogtip/:id",
    page: <Program />,
  },
  {
    name: "certification",
    path: "/certification/:id",
    key: "certification",
    route: "/certification",
    page: <Program />,
  },
  {
    name: "program",
    path: "/program/:id",
    key: "program",
    route: "/program",
    page: <Program />,
  },
  {
    name: "become-instructor",
    path: "/become-instructor",
    key: "become-instructor",
    route: "/become-instructor",
    page: <BecomeInstructor />,
  },
  {
    name: "hire-us",
    path: "/hire-us",
    key: "hire-us",
    route: "/hire-us",
    page: <HireUs />,
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    key: "terms-and-conditions",
    route: "/terms-and-conditions",
    page: <TermsAndCondition />,
  },
  {
    name: "refund-policy",
    path: "/refund-policy",
    key: "refund-policy",
    route: "/refund-policy",
    page: <RefundPolicy />,
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    key: "privacy-policy",
    route: "/privacy-policy",
    page: <PrivacyPolicy />,
  },
  // {
  //   name: "enroll-now",
  //   path: "/enroll-now/:type/:id",
  //   key: "enroll-now",
  //   route: "/enroll-now/:id",
  //   page: <EnrollNow />,
  // },
  {
    name: "thank-you",
    path: "/thank-you/:date/:currency/:value",
    key: "thank-you",
    route: "/thank-you/:date/:currency/:value",
    page: <ThankYou />,
  },
  {
    name: "dashboard",
    path: "/dashboard/",
    key: "dashboard",
    route: "/dashboard/",
    page: <DashBoardRoot />,
    private: true,
  },
  {
    name: "dashboard-course",
    path: "/dashboard/course",
    key: "dashboard-course",
    route: "/dashboard/course",
    page: <DashBoardRoot />,
    private: true,
  },
  {
    name: "dashboard-achievement",
    path: "/dashboard/achievement",
    key: "dashboard-achievement",
    route: "/dashboard/achievement",
    page: <DashBoardRoot />,
    private: true,
  },
  {
    name: "dashboard-course-id",
    path: "/dashboard/course/:id",
    key: "dashboard-course-id",
    route: "/dashboard/course/:id",
    page: <DashBoardRoot />,
    private: true,
  },
  {
    name: "dashboard-projects",
    path: "/dashboard/projects",
    key: "dashboard-projects",
    route: "/dashboard/projects",
    page: <DashBoardRoot />,
    private: true,
  },
  {
    name: "dashboard-schedule",
    path: "/dashboard/schedule",
    key: "dashboard-schedule",
    route: "/dashboard/schedule",
    page: <DashBoardRoot />,
    private: true,
  },
  {
    name: "dashboard-profile",
    path: "/dashboard/profile",
    key: "dashboard-profile",
    route: "/dashboard/profile",
    page: <DashBoardRoot />,
    private: true,
  },

  {
    name: "/dashboard/course/overview-modules",
    path: "/dashboard/course/overview-modules",
    key: "/dashboard/course/overview-modules",
    route: "/dashboard/course/overview-modules",
    page: <DashBoardRoot />,
    private: true,
  },

  {
    name: "/dashboard/course/video/:id/:type",
    path: "/dashboard/course/video/:id/:type",
    key: "/dashboard/course/video/:id/:type",
    route: "/dashboard/course/video/:id/:type",
    page: <DashBoardRoot />,
    private: true,
  },

  {
    name: "/dashboard/projects/list",
    path: "/dashboard/projects/list",
    key: "/dashboard/projects/list",
    route: "/dashboard/projects/list",
    page: <DashBoardRoot />,
    private: true,
  },
  {
    name: "/dashboard/projects/list/:id",
    path: "/dashboard/projects/list/:id",
    key: "/dashboard/projects/list/:id",
    route: "/dashboard/projects/list/:id",
    page: <DashBoardRoot />,
    private: true,
  },

  {
    name: "/dashboard/profile/edit",
    path: "/dashboard/profile/edit",
    key: "/dashboard/profile/edit",
    route: "/dashboard/profile/edit",
    page: <DashBoardRoot />,
    private: true,
  },
  {
    name: "/dashboard/profile/change-password",
    path: "/dashboard/profile/change-password",
    key: "/dashboard/profile/change-password",
    route: "/dashboard/profile/change-password",
    page: <DashBoardRoot />,
    private: true,
  },
  {
    name: "bootcamp",
    path: "/bootcamp",
    key: "bootcamp",
    route: "/bootcamp",
    page: <Bootcamp />,
  },

  {
    name: "oyip",
    path: "/oyip",
    key: "oyip",
    route: "/oyip",
    page: <Bootcamp />,
  },
  {
    name: "login",
    path: "/login",
    key: "login",
    route: "/login",
    page: <AuthRootPage />,
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    key: "forgot-password",
    route: "/forgot-password",
    page: <AuthRootPage />,
  },
  {
    name: "new-password",
    path: "/new-password",
    key: "new-password",
    route: "/new-password",
    page: <AuthRootPage />,
  },
  {
    name: "verify-otp",
    path: "/verify-otp",
    key: "verify-otp",
    route: "/verify-otp",
    page: <AuthRootPage />,
  },
  {
    name: "enroll-now",
    path: "/enroll-now/:type/:id",
    key: "enroll-now",
    route: "/enroll-now/:type/:id",
    page: <EnrollPage />,
  },
  {
    name: "masterclass",
    path: "/masterclass/:id",
    key: "masterclass",
    route: "/masterclass/:id",
    page: <Masterclass />,
  },
  {
    name: "test",
    path: "/test/",
    key: "test",
    route: "/test/",
    page: <Test />,
  },
  // {
  //   name: "blog-dashboard-register",
  //   path: "/blog-dashboard/register",
  //   key: "blog-dashboard/register",
  //   route: "/blog-dashboard/register",
  //   page: <BlogDashboardRegister />,
  // },
  {
    name: "blog-dashboard",
    path: "/blog-dashboard",
    key: "blog-dashboard",
    route: "/blog-dashboard",
    page: <BlogDashBoard />,
  },
  {
    name: "blog-dashboard-update-profile",
    path: "/blog-dashboard/update-profile",
    key: "blog-dashboard/update-profile",
    route: "/blog-dashboard/update-profile",
    page: <BlogDashBoard />,
  },
  {
    name: "blog-dashboard-update-password",
    path: "/blog-dashboard/update-password",
    key: "blog-dashboard/update-password",
    route: "/blog-dashboard/update-password",
    page: <BlogDashBoard />,
  },
  {
    name: "blog-dashboard-profile",
    path: "/blog-dashboard/profile",
    key: "blog-dashboard/profile",
    route: "/blog-dashboard/profile",
    page: <BlogDashBoard />,
  },
  {
    name: "blog-dashboard-profile-submitted",
    path: "/blog-dashboard/profile/submitted",
    key: "blog-dashboard/profile/submitted",
    route: "/blog-dashboard/profile/submitted",
    page: <BlogDashBoard />,
  },
  {
    name: "blog-dashboard-edit-blog",
    path: "/blog-dashboard/edit-blog/:id",
    key: "blog-dashboard-edit-blog",
    route: "/blog-dashboard/edit-blog/:id",
    page: <BlogDashBoard />,
  },
  {
    name: "blog-dashboard-profile-published",
    path: "/blog-dashboard/profile/published",
    key: "blog-dashboard/profile/published",
    route: "/blog-dashboard/profile/published",
    page: <BlogDashBoard />,
  },
  {
    name: "blog-dashboard-home-submitted",
    path: "/blog-dashboard/home/submitted",
    key: "blog-dashboard/home/submitted",
    route: "/blog-dashboard/home/submitted",
    page: <BlogDashBoard />,
  },
  {
    name: "blog-dashboard-home-published",
    path: "/blog-dashboard/home/published",
    key: "blog-dashboard/home/published",
    route: "/blog-dashboard/home/published",
    page: <BlogDashBoard />,
  },
  {
    name: "blog-dashboard-choose-domain",
    path: "/blog-dashboard/create-blog",
    key: "blog-dashboard/choose-domain",
    route: "/blog-dashboard/create-blog",
    page: <BlogDashBoard />,
  },
  {
    name: "blog-dashboard-upload-thumb",
    path: "/blog-dashboard/upload-thumb",
    key: "blog-dashboard/upload-thumb",
    route: "/blog-dashboard/upload-thumb",
    page: <BlogDashBoard />,
  },

  {
    name: "blog-dashboard-insert-content",
    path: "/blog-dashboard/insert-content",
    key: "blog-dashboard/insert-content",
    route: "/blog-dashboard/insert-content",
    page: <BlogDashBoard />,
  },

  {
    name: "blog-register",
    path: "/blog-register",
    key: "/blog-register",
    route: "/blog-register",
    page: <BlogDashboardRegister />,
  },

  {
    name: "blog-dashboard/home",
    path: "/blog-dashboard/home",
    key: "blog-dashboard/home",
    route: "/blog-dashboard/home",
    page: <BlogDashBoard />,
  },

  {
    name: "blog-signup",
    path: "/blog-signup",
    key: "blog-signup",
    route: "/blog-signup",
    page: <BlogSignUp />,
  },

  {
    name: "blog-otp",
    path: "/blog-otp",
    key: "blog-otp",
    route: "/blog-otp",
    page: <BlogOtp />,
  },
  {
    name: "user-accounts",
    path: "/user-accounts",
    key: "user-accounts",
    route: "/user-accounts",
    page: <AuthRootPage />,
  },
  {
    name: "certificate-preview",
    path: "/certificate-preview/:batch/:id",
    key: "certificate-preview",
    route: "/certificate-preview/:batch/:id",
    page: <CertificateInfo />,
  },
  {
    name: "/dashboard/ticket",
    path: "/dashboard/ticket",
    key: "/dashboard/ticket",
    route: "/dashboard/ticket",
    page: <DashBoardRoot />,
    private: true,
  },

  {
    name: "/dashboard/ticket/:id",
    path: "/dashboard/ticket/:id",
    key: "/dashboard/ticket/:id",
    route: "/dashboard/ticket/:id",
    page: <DashBoardRoot />,
    private: true,
  },
  {
    name: "/dashboard/plans-billings",
    path: "/dashboard/plans-billings",
    key: "/dashboard/plans-billings",
    route: "/dashboard/plans-billings",
    page: <DashBoardRoot />,
    private: true,
  },
  {
    name: "/dashboard/resources",
    path: "/dashboard/resources",
    key: "/dashboard/resources",
    route: "/dashboard/resources",
    page: <DashBoardRoot />,
    private: true,
  },
  // {
  //   name: "calendar",
  //   path: "/calendar",
  //   key: "calendar",
  //   route: "/calendar",
  //   page: <LoginSignout />,
  // },
];

export default RoutesArr;
