import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, HashRouter } from "react-router-dom";
import configureStore from "./store/configureStore";

import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";

const store = configureStore();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId='548658123196-1rcrde10hbebhf6dr8e0ijo6mipn0d2j.apps.googleusercontent.com'>
      <Provider store={store}>
        <App />
      </Provider>
    </GoogleOAuthProvider>
  </BrowserRouter>
);
